<template>
  <section class="newCoringsValidationPopIn">
    <v-card flat outlined class="newCoringsValidationPopIn__card">
      <h3>Valider l'envoi au laboratoire ?</h3>
      <v-card-text class="card_text">
        <p>
          Voulez-vous soumettre votre campagne et ses données à votre
          prestataire d'analyses ?
        </p>
        <v-spacer></v-spacer>
        <p>Après envoi, cette campagne ne sera plus modifiable</p>
        <p><b>Nous vous rappelons que ceci est un engagement contractuel</b></p>
        <p v-if="!hasPrestations" class="danger">
          Vous devez d'abord paramétrer les prestations de cette campagne
        </p>
        <br />
        <p><b>Pensez à réaliser votre commande sur GX</b></p>
      </v-card-text>
      <v-card-actions class="justify-center">
        <MediumButtonSlot @click="closePopin"> Annuler </MediumButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="openPrestationsModale">
          Paramétrer les prestations
        </HighButtonSlot>
        <HighButtonSlot :_disabled="!hasPrestations" @click="confirmSending">
          Valider
        </HighButtonSlot>
      </v-card-actions>
    </v-card>
    <ProvidersModale
      v-if="isProvidersModaleOpen"
      :agency="agency"
      :campaign="this.$route.params.id"
      @close="hasCampaignPrestations()"
    />
  </section>
</template>

<script>
import ProvidersModale from "@/components/providers/Providers.table.modal";
export default {
  name: "newCoringsValidationPopIn",
  components: {
    ProvidersModale,
  },
  props: ['agency'],
  data() {
    return {
      isProvidersModaleOpen: false,
      hasPrestations: false,
    };
  },
  methods: {
    openPrestationsModale() {
      this.isProvidersModaleOpen = true;
    },
    closePopin() {
      this.$emit("closePopin", false);
    },
    confirmSending() {
      this.$emit("sendCoring");
      this.closePopin();
    },
    async hasCampaignPrestations() {
      this.isProvidersModaleOpen = false;
      const prestations = await this.$api.campaigns.getPrestations(
        this.$route.params.id
      );
      this.hasPrestations = prestations.length != 0;
    },
  },
  async mounted() {
    this.$root.$on("closeCurrent", this.closePopin);
    this.hasCampaignPrestations();
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") this.closePopin();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.newCoringsValidationPopIn {
  @extend %popinView;

  .v-card {
    width: 50%;
    padding: 20px 20px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .danger {
    color: red !important;
  }
}
</style>
