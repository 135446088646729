<template>
  <v-card class="pa-3 coreCard" outlined>
    <div class="coreCard__header">
      <span class="core-title">Carotte #{{ core.number }}</span>
      <v-chip
        small
        class="white--text mt-3"
        style="max-width: 200px"
        :color="chip.color"
      >
        <v-icon small left> {{ chip.icon }} </v-icon>
        <b>{{ $t(getStatus) }}</b>
      </v-chip>
    </div>

    <div class="coreCard__actions">
      <v-btn class="ma-2 m_brown" text icon @click="jumpToCore">
        <v-icon class="icon__core_card">mdi-crosshairs-gps</v-icon>
      </v-btn>
      <v-btn class="ma-2 m_brown" text icon>
        <v-icon class="icon__core_card" @click="toggleNewCoreForm">
          {{
            isFormDisabled && campaignStatus !== "awaiting_validation"
              ? "mdi-eye"
              : "mdi-pencil"
          }}
        </v-icon>
      </v-btn>
      <v-btn
        class="ma-2 m_brown"
        text
        icon
        @click="deleteCore(core.id)"
        v-if="isDeleteDisplayed"
      >
        <v-icon class="icon__core_card">mdi-delete</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import coreStatus from "../../../utils/core";

export default {
  name: "CoreCard",
  props: ["core", "updateCores", "isStudyManager", "campaignStatus"],
  data() {
    return {
      number: 0,
      editedCoringId: 0,
      isFormDisabled: false,
      isDeleteDisplayed: false,
      chip: {
        color: "#2a7221",
        icon: "mdi-check"
      }
    };
  },
  computed: {
    getStatus() {
      if (this.isStudyManager && this.core.status == "sent_to_study_manager") {
        return "to_complete";
      } else return this.core.status;
    }
  },
  mounted() {
    this.editedCoringId = this.core.id;
    this.number = this.core.number;
    this.updateFromUtils();
  },
  methods: {
    async deleteCore(id) {
      if ("number" === typeof id) await this.$api.cores.cancel(id, "");
      this.$emit("deleted", this.core);
    },
    deleteMe() {
      this.$emit("deleteCoreAttempt", this.editedCoringId);
    },
    jumpToCore() {
      this.$root.$emit("jumpToCore", [
        this.core.coordinates.lng,
        this.core.coordinates.lat
      ]);
    },
    toggleNewCoreForm() {
      this.$emit("toggleNewForm", true, this.number);
    },
    updateFromUtils() {
      let statusInfos = coreStatus.getCurrentStatus(this.core.status);
      this.isFormDisabled = !(
        statusInfos.formStatus == 2 ||
        statusInfos.interfacesAndLayersFormStatus == 2
      );
      this.isDeleteDisplayed =
        statusInfos.formStatus == 2 &&
        statusInfos.interfacesAndLayersFormStatus == 0;
      this.chip = statusInfos.chip;
    }
  },
  watch: {
    updateCores() {
      this.core.status = this.updateCores;
      this.updateFromUtils();
    },
    core() {
      this.number = this.core.number;
    }
  }
};
</script>

<style lang="scss">
@media (max-width: 1450px) {
  .coreCard {
    flex-direction: column;
    justify-content: flex-start;
    min-width: 200px;

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.coreCard {
  display: flex;
  justify-content: space-between;
}
.core-title {
  display: block;
  font-weight: bold;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  color: #383838;
  text-align: left;
}
.icon__core_card {
  font-size: 30px !important;
}
</style>
