<template>
  <section class="newCoringsValidationPopIn">
    <v-card flat outlined class="newCoringsValidationPopIn__card">
      <v-card-title class="center justify-center" align-items>
        <h3>Valider le placement des carottes ?</h3>
      </v-card-title>
      <v-card-text class="card_text">
        <p>
          Voulez-vous soumettre votre campagne à votre technicien de carottage ?
        </p>
        <p>Après envoi, cette campagne ne sera plus modifiable</p>
      </v-card-text>
      <v-card-actions>
        <MediumButtonSlot @click="closePopin"> Annuler </MediumButtonSlot>
        <v-spacer></v-spacer>
        <HighButtonSlot @click="confirmCorings"> Valider </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "newCoringsValidationPopIn",
  methods: {
    closePopin() {
      this.$emit("closePopin", false);
    },
    confirmCorings() {
      this.$emit("newCorings");
      this.closePopin();
    },
  },

  mounted() {
    this.$root.$on("closeCurrent", this.closePopin);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.newCoringsValidationPopIn {
  @extend %popinView;

  .v-card {
    width: 50%;
    padding: 20px 20px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }
}
</style>
