<template>
  <div class="pa-4">
    <CoreDeletePopin
      v-show="CoreDeletePopin.display"
      :id="CoreDeletePopin.id"
      @closePopin="deleteCoreClosePopin"
    />
    <NewCoringsValidationPopIn
      v-show="isNewCoringsValidationPopInDisplay"
      @closePopin="isNewCoringsValidationPopInDisplay = false"
      @newCorings="checkCorings"
    />
    <CoringCampaignValidationPopIn
      v-show="isCoringCampaignValidationPopInDisplay"
      @closePopin="isCoringCampaignValidationPopInDisplay = false"
      @validateCampaign="validateCampaign"
    />
    <SendCoringCampaignToProviderValidationPopin
      :agency="agencyId"
      v-show="isCoringCampaignSendDataPopinDisplay"
      @closePopin="isCoringCampaignSendDataPopinDisplay = false"
      @sendCoring="sendToProvider"
    />
    <div class="d-flex justify-space-between mb-3">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          :to="{ name: 'project', params: { id: workspaceId } }"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(coringCampaign.name) }}</h1>
          <i>{{ $t(coringCampaign.status) }}</i>
        </div>
      </div>
      <div class="ma-2">
        <MediumButtonSlot
          color="black"
          class="mr-2"
          @click="getTemporaryPDF()"
          v-if="isGetTemporaryPDFButtonDisplayed"
        >
          <v-icon left dark> mdi-file </v-icon>
          Rapport provisoire
        </MediumButtonSlot>
        <HighButtonSlot
          color="black"
          depressed
          class="ma-2 white--text"
          @click="refreshData()"
          :_disabled="isGatherCommandInProgress"
          v-if="isGatherDataButtonDisplayed"
        >
          <v-icon
            :class="isGatherCommandInProgress ? 'rotating' : ''"
            left
            dark
          >
            mdi-refresh
          </v-icon>
          Rafraîchir les données
        </HighButtonSlot>

        <HighButtonSlot
          color="black"
          depressed
          class="ma-2 white--text"
          :_disabled="dashboardBtnStatus == 1"
          v-if="dashboardBtnStatus > 1"
          :_to="{
            name: 'coring-campaign-dashboard',
            params: { id: $route.params.id },
          }"
        >
          <v-icon left dark> mdi-file-document-outline </v-icon>
          Accéder aux données
        </HighButtonSlot>
        <HighButtonSlot
          @click="onActionBtnClicked"
          :_disabled="isValidateBtnDisabled || isSendCommandInProgress"
          v-if="
            coordinates &&
            coordinates.length > 0 &&
            validateBtnStatus != 0 &&
            $hasRight('campaigns.configureCoringAndStart')
          "
        >
          <v-icon
            left
            color="white"
            :class="isSendCommandInProgress ? 'rotating' : ''"
          >
            {{ isSendCommandInProgress ? "mdi-refresh" : "mdi-check-outline" }}
          </v-icon>
          {{ validateBtnText }}
        </HighButtonSlot>
      </div>
    </div>
    <div class="rowContainer">
      <v-col class="pl-2">
        <v-card outlined>
          <base-map
            class="rowContainer__mapCol"
            v-if="coordinates != undefined"
            @newCoreDrawn="newCoreDrawn"
            :enableDraw="enableDraw"
            :cores="coordinates"
            :campaign="coringCampaign"
            :resultMode="false"
          />
        </v-card>
      </v-col>
      <v-col class="CoringNav__coringList pr-2">
        <v-card
          v-if="!isNewEntryFormDisplayed"
          flat
          class="pa-0"
          style="text-align: center"
        >
          <core-card
            class="core_card"
            v-for="core in coordinates"
            :key="core.id"
            :core="core"
            :isStudyManager="$hasRight('campaigns.configureCoringAndStart')"
            :campaignStatus="coringCampaign.status"
            @deleteCoreAttempt="deleteCoreOpenPopin"
            @deleted="removeCore"
            @toggleNewForm="toggleNewForm"
            @update="updateInfos"
          />

          <HighButtonSlot
            @click="onClickAddCore"
            class="mt-5"
            :_disabled="addCoreBtnStatus == 1"
            v-if="
              addCoreBtnStatus != 0 &&
              $hasRight('campaigns.configureCoringAndStart')
            "
          >
            <v-icon left>mdi-map-marker-plus-outline</v-icon>
            Ajouter une carotte
          </HighButtonSlot>
        </v-card>
        <CoreForm
          v-else
          @toggleNewForm="toggleNewForm"
          @confirmNewCore="saveCoring"
          @updateCoring="updateCoring"
          :infos="globalInfos"
          :coordinates="coordinates"
          :number="number"
          :workspaceId="coringCampaign.workspace.id"
          :campaignStatus="coringCampaign.status"
          :defaultValues="coringCampaign.defaultCoreParams"
          ref="updateCards"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import BaseMap from "../../components/coring/map/Coring.map.vue";
import CoreCard from "../../components/coring/map/Core.card.vue";
import CoreDeletePopin from "../../components/coring/map/DeleteCore.popin.vue";
import NewCoringsValidationPopIn from "../../components/coring/prepare/newCoringsValidation.popin.vue";
import CoringCampaignValidationPopIn from "../../components/coring/prepare/CoringCampaignValidate.popin.vue";
import CoreForm from "../../components/coring/prepare/Core.form.vue";
import coringCampaignStatus from "../../utils/coringCampaign";
import SendCoringCampaignToProviderValidationPopin from "@/components/providers/sendCoringCampaignToProviderValidation.popin.vue";

export default {
  name: "CoringCampaign",
  components: {
    CoreCard,
    BaseMap,
    CoreDeletePopin,
    CoreForm,
    NewCoringsValidationPopIn,
    CoringCampaignValidationPopIn,
    SendCoringCampaignToProviderValidationPopin,
  },
  data() {
    return {
      url:
        window.location.origin +
        "/share-link?token=eajklsdDZSdgfHsJjfEYhfe4lsdDhdgf5hSEafAlsdDhdgfk",
      isGenerateLinkModaleOpen: false,
      coringCampaign: { name: "", status: "" },
      workspaceId: "1",
      coordinates: undefined,
      enableDraw: false,
      CoreDeletePopin: {
        display: false,
        id: -1,
      },
      isNewEntryFormDisplayed: false,
      editedCoringId: null,
      isNewCoringsValidationPopInDisplay: false,
      updateCores: false,
      validateBtnText: "",
      validateBtnStatus: 1,
      addCoreBtnStatus: 0,

      isCoringCampaignValidationPopInDisplay: false,
      isCoringCampaignSendDataPopinDisplay: false,
      dashboardBtnStatus: 1,
      isSendCommandInProgress: false,
      isGatherCommandInProgress: false,
    };
  },
  mounted() {
    this.getViewInfos();

    this.$root.$on("deleteCoring", this.deleteCore);
  },
  methods: {
    async getTemporaryPDF() {
      const response = await this.$api.coringCampaigns.getTemporaryPDF(
        this.$route.params.id
      );
      var newBlob = new Blob([response], { type: "application/pdf" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = this.coringCampaign.name;
      window.open(link, "_blank");
    },
    async generateShareLink() {
      const guestUser = await this.$api.campaigns.inviteGuestUser(
        this.$route.params.id
      );
      this.url =
        window.location.origin + "/share-link?token=" + guestUser.token;
      this.copyTextToClipboard();
    },
    fallbackCopyTextToClipboard() {
      this.$refs.generatedLink.focus();
      this.$refs.generatedLink.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("unable to copy", err);
      }
    },
    copyTextToClipboard() {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard();
        return;
      }
      navigator.clipboard.writeText(this.url);
      this.$toast("success", "Lien copié dans le presse papier !");
    },
    removeCore(core) {
      const coreIndex = this.coordinates.findIndex(
        (c) => c.number === core.number
      );
      // Identifier et sauvegarder l'index de la core deleted
      // si l'index est inférieur à l'index de la carotte x des coords, alors index -1
      // si l'index est supérieur à l'index de la carotte x des coords, alors rien
      // supprime du tableau de cooord l'index de la core deleted
      //

      this.coordinates.forEach((e) => {
        if (coreIndex < e.number && "waiting_validation_no_data" === e.status) {
          e.number--;
        }
      });
      this.coordinates.splice(coreIndex, 1);

      this.getViewInfos();
    },
    openGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = true;
    },
    closeGenerateLinkModale() {
      this.isGenerateLinkModaleOpen = false;
    },
    getViewInfos() {
      let tempCores = [];
      if (undefined !== this.coordinates)
        tempCores = this.coordinates.filter(
          (core) => core.status === "waiting_validation_no_data"
        );

      this.$api.coringCampaigns
        .findById(this.$route.params.id)
        .then((campaign) => {
          this.coringCampaign = campaign;
          this.workspaceId = this.coringCampaign.workspace.id;
          this.fetchBtnDisplayActiveStatusAndText(this.coringCampaign.status);

          this.coringCampaign.defaultCoreParams.structure_type_id =
            this.coringCampaign.defaultCoreParams.structureType;

          this.coringCampaign.defaultCoreParams.structure_type_id =
            this.coringCampaign.defaultCoreParams.structureType;

          this.$api.cores
            .findByCampaign(this.$route.params.id)
            .then((cores) => {
              tempCores = tempCores.filter((core) => {
                const index = cores.findIndex((c) => c.number === core.number);
                return index === -1;
              });

              this.coordinates = [
                ...cores.map((ele) => {
                  ele.coordinates = { lng: ele.lng, lat: ele.lat };
                  return ele;
                }),
                ...tempCores,
              ].sort((e, f) => e.number - f.number);
            });
        });
    },
    async refreshData() {
      this.isGatherCommandInProgress = true;
      await this.$api.coringCampaigns.getFromProvider(this.$route.params.id);
      this.isGatherCommandInProgress = false;
      this.getViewInfos();
    },
    async sendToProvider() {
      this.isSendCommandInProgress = true;

      await this.$api.coringCampaigns.sendToProvider(this.$route.params.id);

      this.isSendCommandInProgress = false;
      this.getViewInfos();
    },
    async checkCorings() {
      // Update the campaign status
      await this.updateCampaign("programing_validated");
      this.getViewInfos();
    },
    checkOptionalParameters(thisCoring) {
      let optionalParametersFill = {};
      if (thisCoring.abscissa == null || thisCoring.abscissa === "") {
        optionalParametersFill.abscissa = null;
      } else {
        optionalParametersFill.abscissa = thisCoring.abscissa;
      }
      if (thisCoring.landmark == null || thisCoring.landmark === "") {
        optionalParametersFill.landmark = null;
      } else {
        optionalParametersFill.landmark = thisCoring.landmark;
      }

      if (thisCoring.note == null) {
        optionalParametersFill.note = "RAS";
      } else {
        optionalParametersFill.note = thisCoring.note;
      }

      if (thisCoring.direction == null) {
        optionalParametersFill.direction = 1;
      } else {
        optionalParametersFill.direction = thisCoring.direction;
      }

      return optionalParametersFill;
    },
    deleteCore(e) {
      this.coordinates = this.coordinates.filter((coord) => coord.id != e);
      this.coordinates.forEach((el, i) => {
        el.number = i + 1;
      });
      this.CoreDeletePopin.display = false;
      this.CoreDeletePopin.id = -1;
    },
    deleteCoreOpenPopin(e) {
      this.CoreDeletePopin.id = e;
      this.CoreDeletePopin.display = true;
    },
    deleteCoreClosePopin() {
      this.CoreDeletePopin.display = false;
      this.CoreDeletePopin.id = -1;
    },
    fetchBtnDisplayActiveStatusAndText(status) {
      let statusInfos = coringCampaignStatus.getCurrentStatus(status);
      this.validateBtnText = statusInfos.actionBtn.label;
      this.validateBtnStatus = statusInfos.actionBtn.status;

      this.addCoreBtnStatus = statusInfos.coreBtn.status;

      this.dashboardBtnStatus = statusInfos.dashboardBtnStatus;
    },
    newCoreDrawn() {
      this.enableDraw = false;
    },
    async newCorings() {
      const cores = await this.$api.cores.findByCampaign(this.$route.params.id);
      this.checkCorings(cores);
    },
    onActionBtnClicked() {
      let campaignStatus = this.coringCampaign.status;
      if (campaignStatus == "awaiting_validation")
        this.toggleNewCoresValidationPopIn();
      else if (campaignStatus == "taken") {
        this.isCoringCampaignValidationPopInDisplay = true;
      } else if (campaignStatus == "taking_validated") {
        this.isCoringCampaignSendDataPopinDisplay = true;
      }
    },
    onClickAddCore() {
      this.enableDraw = !this.enableDraw;
    },
    async postNewPicture(file, coringId) {
      // Form data conversion
      const formData = new FormData();
      formData.append("coreId", coringId);
      formData.append("file", file.file);

      try {
        let corePicture = await this.$api.corePictures.upload(formData, {
          coreId: coringId,
          type: "technicianHelp",
          id: file.id,
        });
        return corePicture;
      } catch (error) {
        console.log(error);
      }
    },
    saveCoring(thisCoring) {
      let optionalParametersFill = this.checkOptionalParameters(thisCoring);

      thisCoring.direction = optionalParametersFill.direction;
      thisCoring.landmark = optionalParametersFill.landmark;
      thisCoring.abscissa = optionalParametersFill.abscissa;
      thisCoring.note = optionalParametersFill.note;

      this.$api.cores
        .create({
          campaignId: this.$route.params.id,
          number: thisCoring.number,
          roadId: thisCoring.road.id,
          lng: thisCoring.coordinates.lng,
          lat: thisCoring.coordinates.lat,
          diameter: thisCoring.diameter,
          structureType: thisCoring.structureType,
          isFullStructure: thisCoring.isFullStructure,
          lane: thisCoring.lane,
          direction: thisCoring.direction,
          landmark: thisCoring.landmark, //landmark et PR = borne kilometrique
          lateralPosition: thisCoring.lateralPosition,
          abscissa: thisCoring.abscissa,
          note: thisCoring.note,
          NR_name: "",
        })
        .then(async (core) => {
          thisCoring.id = core.id;
          const promises = [];
          for (const pic of thisCoring.pictures.filter(
            (pic) => pic.type == "technicianHelp"
          )) {
            promises.push(this.postNewPicture(pic, core.id));
          }
          let corepics = await Promise.all(promises);
          thisCoring.corePictures = corepics;
          this.updateCards(thisCoring, "waiting");
        });
      this.getViewInfos();
    },
    toggleNewCoresValidationPopIn() {
      this.isNewCoringsValidationPopInDisplay = true;
    },
    toggleNewForm(isNewEntryFormDisplayed, number) {
      this.number = number;
      this.isNewEntryFormDisplayed = isNewEntryFormDisplayed;
    },
    async updateCampaign(status) {
      let currentCampaign = this.coringCampaign;

      await this.$api.coringCampaigns.editStatus(currentCampaign.id, status);
      //TODO maybe but these two lines in a method ?
      this.coringCampaign.status = status;
      this.fetchBtnDisplayActiveStatusAndText(status);
    },
    updateCards(thisCoring, status) {
      thisCoring.status = status;
      this.updateCore(thisCoring);
    },
    updateCore(newCore) {
      this.isNewEntryFormDisplayed = false;
      this.coordinates = this.coordinates.map((coord) => {
        return coord.number === newCore.number ? newCore : coord;
      });
    },
    updateCoring(core) {
      this.$api.cores
        .edit(core.id, {
          number: core.number,
          roadId: core.road.id,
          lng: core.coordinates.lng,
          lat: core.coordinates.lat,
          diameter: core.diameter,
          structureType: core.structureType,
          isFullStructure: core.isFullStructure,
          lane: core.lane,
          direction: core.direction,
          landmark: core.landmark,
          lateralPosition: core.lateralPosition,
          abscissa: core.abscissa,
          note: core.note,
        })
        .then(async (coreP) => {
          core.coordinates = { lng: core.lng, lat: core.lat };
          const promises = [];
          for (const pic of core.pictures.filter(
            (pic) => pic.type == "technicianHelp"
          )) {
            promises.push(this.postNewPicture(pic, core.id));
          }
          let returnedCorePictures = await Promise.all(promises);
          let tempCorePictures = {};
          core.corePictures.forEach((cp) => (tempCorePictures[cp.id] = cp));
          returnedCorePictures.forEach((cp) => (tempCorePictures[cp.id] = cp));
          core.corePictures = Object.values(tempCorePictures);
          this.updateCards(core, coreP.status);
        });
    },
    updateInfos(infos) {
      this.coordinates = this.coordinates.map((coord) =>
        coord.id == infos.id ? infos : coord
      );
      this.$emit("input", this.coordinates);
      this.updateCores = infos.status;
    },
    validateCampaign() {
      this.isCoringCampaignValidationPopInDisplay = false;
      this.updateCampaign("taking_validated");
    },
  },
  computed: {
    isGetTemporaryPDFButtonDisplayed() {
      return (
        this.coringCampaign.status === "taken" ||
        this.coringCampaign.status === "taking_validated" ||
        this.coringCampaign.status === "sent_for_analysis"
      );
    },
    agencyId() {
      if ("" === this.coringCampaign.name) return 0;

      return this.coringCampaign.workspace.agency.id;
    },
    globalInfos() {
      const nullObj = {
        lastRoad: null,
        lastDiameter: null,
      };

      if (!this.coordinates || 1 >= this.coordinates.length) return nullObj;
      let lastValidatedCore = {};

      // loop array in reverse to find last validated core (core with an int id)
      for (let i = this.coordinates.length - 2; i >= 0; i--) {
        const core = this.coordinates[i];
        if ("string" === typeof core.id) continue;

        lastValidatedCore = core;
        break;
      }

      if (!lastValidatedCore || !lastValidatedCore?.id) return nullObj;

      return {
        lastRoad: lastValidatedCore.road,
        lastDiameter: lastValidatedCore.diameter,
      };
    },
    isGatherDataButtonDisplayed() {
      return (
        (this.coringCampaign.status === "sent_for_analysis" ||
          this.coringCampaign.status === "results_obtained") &&
        this.$hasRight("campaigns.updateStatus")
      );
    },
    isValidateBtnDisabled() {
      let shouldIt = true;
      if (this.coringCampaign) {
        shouldIt = this.coordinates == null || this.coordinates.length == 0;
        if (!shouldIt && this.coringCampaign.status == "awaiting_validation") {
          this.coordinates.forEach((el) => {
            shouldIt =
              shouldIt ||
              !(
                el.road != null &&
                el.lane != null &&
                el.isFullStructure != null &&
                el.diameter != null
              );
          });
        } else if (!shouldIt && this.coringCampaign.status == "taken") {
          this.coordinates.forEach(
            (el) =>
              (shouldIt =
                shouldIt ||
                (el.status != "validated" && el.status != "cancelled"))
          );
        }
      }
      return shouldIt || this.validateBtnStatus == 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

@media (max-width: 700px) {
  .rowContainer {
    flex-direction: column;

    &__mapCol {
      height: 50vh;
    }

    .CoringNav__coringList {
      max-width: 100%;
      margin-bottom: 100px;
    }
  }
}
.rowContainer {
  display: flex;
  height: calc(100vh - 125px);
}

.campaign-form {
  margin: 20px 30px;
  &__imgBox {
    width: 180px;
    height: 162px;
    background-repeat: none;
    background-position: center center;
    background-size: cover;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 4px;
    transition: border 0.2s ease;
    &:hover {
      border: 1px rgba(0, 0, 0, 0.8) solid;
    }
    display: flex;
  }
  .center {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.CoringNav__coringList {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 125px);
  max-width: 30%;

  .core_card {
    margin-bottom: 10px;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>
