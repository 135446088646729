<template>
  <div class="Popin">
    <v-card class="providerCard" flat outlined>
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-card-title> Choix des prestations de la campagne </v-card-title>
        </div>
        <div class="d-flex align-center">
          <v-icon class="mr-4 pa-1 m_brown" @click="$emit('close')"
            >mdi-close</v-icon
          >
        </div>
      </div>
      <v-card-text>
        <v-stepper flat tile elevation="0" v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1" class="primary-step">
              Choix du prestataire
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2" class="primary-step">
              Sélection des prestations
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step :complete="step > 3" step="3" class="primary-step">
              Validation
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container fluid>
                <v-data-table
                  v-model="selectedProvider"
                  :items="providers"
                  :headers="providerHeaders"
                  :singleSelect="true"
                  show-select
                  :items-per-page="5"
                  item-key="id"
                  class="elevation-1"
                  :loading="providers === []"
                  :footer-props="{
                    'disable-items-per-page': true,
                  }"
                >
                </v-data-table>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <HighButtonSlot
                  :_disabled="null === selectedProvider[0]"
                  class="right_btn"
                  @click="step = 2"
                  >Continuer</HighButtonSlot
                >
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-container fluid>
                <div class="selectedPrestas">
                  <v-chip
                    small
                    class="singlePresta"
                    v-for="prestation in selectedPrestations"
                    :key="prestation.id"
                    >{{ prestation.name }}
                    <v-icon
                      small
                      class="removePrestaIcon"
                      @click="unselect(prestation.id)"
                      >mdi-close</v-icon
                    ></v-chip
                  >
                </div>
                <v-data-table
                  v-model="selectedPrestations"
                  :items="mappedPrestations"
                  :headers="prestationHeaders"
                  show-select
                  item-key="id"
                  class="elevation-1"
                  :loading="prestations === []"
                  :footer-props="{
                    'disable-items-per-page': true,
                  }"
                  :items-per-page="10"
                >
                </v-data-table>
              </v-container>
              <v-card-actions>
                <MediumButtonSlot @click="step = 1"
                  >Modifier le prestataire</MediumButtonSlot
                >
                <v-spacer></v-spacer>
                <HighButtonSlot
                  :_disabled="selectedPrestations.length === 0"
                  class="right_btn"
                  @click="step = 3"
                  >Continuer</HighButtonSlot
                >
              </v-card-actions>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-container fluid>
                <div class="recapCard" v-if="null !== selectedProvider[0]">
                  <h3>Récapitulatif</h3>
                  <v-card-text>
                    <div>
                      <p>
                        <b>Prestataire</b>
                        <span class="m_brown">
                          {{ selectedProvider[0].name || "no provider" }}</span
                        >
                      </p>
                    </div>
                    <br />
                    <v-divider></v-divider>
                    <br />
                    <p>
                      <b>Prestations</b>
                    </p>
                    <br />
                    <v-data-table
                      :items="mappedSelectedPrestations"
                      :headers="prestationHeaders"
                      item-key="id"
                      class="elevation-1"
                      :loading="prestations === []"
                      hide-default-footer
                    >
                    </v-data-table>
                  </v-card-text>
                </div>
              </v-container>
              <v-card-actions>
                <MediumButtonSlot @click="step = 2"
                  >Modifier les prestations</MediumButtonSlot
                >
                <v-spacer></v-spacer>
                <HighButtonSlot class="right_btn" @click="validatePrestations()"
                  >Valider les prestations</HighButtonSlot
                >
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["campaign", "agency"],
  data() {
    return {
      step: 1,
      regExIsTime: new RegExp("^.*(H|J)$"),
      providers: [],
      prestations: [],
      providerHeaders: [
        {
          text: "Prestataire",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      prestationHeaders: [
        {
          text: "Numéro (Areia)",
          sortable: true,
          value: "number",
        },
        {
          text: "Prestation",
          sortable: true,
          value: "nameShort",
        },
        {
          text: "Temps estimé",
          sortable: false,
          value: "estimatedDuration",
        },
      ],
      selectedProvider: [null],
      selectedPrestations: [],
    };
  },
  computed: {
    mappedSelectedPrestations() {
      return this.mappedPrestations.filter(
        (presta) =>
          -1 !== this.selectedPrestations.findIndex((p) => p.id === presta.id)
      );
    },
    mappedPrestations() {
      return this.prestations.map((el) => {
        let nameSplitted = el.name.split(" ");
        let timeParts = nameSplitted.filter((el) => this.regExIsTime.test(el));
        if (timeParts.length >= 1) {
          el.estimatedDuration = timeParts[0];
          el.nameShort = nameSplitted
            .filter((el) => !this.regExIsTime.test(el))
            .join(" ");
        } else {
          el.nameShort = el.name;
          el.estimatedDuration = "/";
        }
        return el;
      });
    },
  },
  methods: {
    unselect(id) {
      const index = this.selectedPrestations.findIndex((p) => p.id === id);
      if (-1 === index) return;

      this.selectedPrestations.splice(index, 1);
    },
    async getCampaignPrestations() {
      this.selectedPrestations = await this.$api.campaigns.getPrestations(
        this.campaign
      );
    },
    async getProviders() {
      const providers = await this.$api.agencies.getAgencyProviders(
        this.agency.id
      );
      this.providers = providers.map((ap) => ap.provider);

      if (0 === this.providers.length) {
        this.$router.push(
          "/admin/organizations/" + this.agency.organization.id
        );
        this.$toast(
          "warning",
          `L'agence ${this.agency.name} nécessite un prestataire pour l'envoi des données`
        );
      }
    },
    async getPrestations(id) {
      this.prestations = await this.$api.prestations.findByProvider(id);
    },
    async validatePrestations() {
      await this.$api.campaigns.editPrestations(
        this.campaign,
        this.selectedPrestations.map((p) => p.id)
      );
      this.$toast("success", "Prestations modifiées");
      this.$emit("close");
    },
  },
  async created() {
    await this.getCampaignPrestations();
    if (this.selectedPrestations.length > 0) {
      this.selectedProvider = [this.selectedPrestations[0].provider];
      this.step = 3;
    }
    this.getProviders();
  },
  watch: {
    selectedProvider() {
      this.getPrestations(this.selectedProvider[0].id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.Popin {
  @extend %popinView;

  .providerCard {
    padding: 0 40px;
  }

  .selectedPrestas {
    display: flex;
    flex-wrap: wrap;

    .removePrestaIcon {
      &:hover {
        color: red;
      }
    }

    .singlePresta {
      margin: 5px;
    }
  }

  .recapCard {
    width: auto;

    p {
      text-align: left;
      margin: 0;
    }
  }
}
</style>
