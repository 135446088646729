import { render, staticRenderFns } from "./Providers.table.modal.vue?vue&type=template&id=790cb086&scoped=true&"
import script from "./Providers.table.modal.vue?vue&type=script&lang=js&"
export * from "./Providers.table.modal.vue?vue&type=script&lang=js&"
import style0 from "./Providers.table.modal.vue?vue&type=style&index=0&id=790cb086&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790cb086",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VChip,VContainer,VDataTable,VDivider,VIcon,VSpacer,VStepper,VStepperContent,VStepperHeader,VStepperItems,VStepperStep})
