<template>
  <form @submit.prevent="submit" class="core_form">
    <v-expansion-panels flat v-model="display" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <h2>Carotte #{{ currentCoring.number }}</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="inner-padding">
          <v-form v-model="isCreateFormValid" ref="core_coreInfo">
            <v-combobox
              class="form-left"
              ref="roadField"
              :autofocus="!isFormDisabled"
              tabindex="1"
              :rules="[$rules.requiredRoad]"
              :items="formRoads"
              :item-text="item => item.name"
              v-model="coringForm.road"
              outlined
              @change="handleRoadChange"
              required
              clearable
              dense
              label="Nom de la rue*"
              :disabled="isFormDisabled"
            ></v-combobox>
            <v-select
              dense
              tabindex="2"
              class="form-inputs"
              v-model="coringForm.direction"
              :items="roadWayField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              label="Sens de la rue*"
              data-vv-name="select"
              outlined
              clearable
              :disabled="isFormDisabled"
              :rules="[$rules.required]"
            ></v-select>
            <v-select
              dense
              tabindex="3"
              class="form-inputs"
              v-model="coringForm.lane_id"
              :items="formVMField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              label="Voies*"
              data-vv-name="select"
              outlined
              clearable
              :disabled="isFormDisabled"
              :rules="[$rules.required]"
            ></v-select>
            <div
              :class="isFormDisabled ? 'disabled form-lateral' : 'form-lateral'"
            >
              <v-row align="center" dense>
                <v-col cols="5">
                  <v-text-field
                    dense
                    tabindex="4"
                    class="lateral-left"
                    v-model="coringForm.landmark"
                    label="PR"
                    placeholder="PR"
                    outlined
                    clearable
                    :disabled="isFormDisabled"
                    :rules="[$rules.onlyNumbers, $rules.FourMax]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center pr-0 pl-0">
                  <p>+</p>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    tabindex="5"
                    dense
                    class="lateral-right"
                    v-model="coringForm.abscissa"
                    label="ABS"
                    placeholder="ABS"
                    outlined
                    clearable
                    :disabled="isFormDisabled"
                    :rules="[$rules.onlyNumbers, $rules.FourMax]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-select
              v-if="false === lateralPosIsDisable"
              tabindex="6"
              dense
              class="form-inputs"
              v-model="coringForm.lateral_position_id"
              :items="lateralPosField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              label="Latéralisation*"
              data-vv-name="select"
              outlined
              clearable
              :disabled="isFormDisabled"
              :rules="[$rules.required]"
            ></v-select>
            <v-select
              dense
              tabindex="7"
              class="form-inputs"
              v-model="coringForm.structure_type_id"
              :items="stTypeField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              label="Type de Structure"
              data-vv-name="select"
              outlined
              clearable
              :disabled="isFormDisabled"
            ></v-select>
            <v-select
              dense
              tabindex="8"
              class="form-inputs"
              v-model="coringForm.isFullStructure"
              :items="stCompleteField"
              :item-text="item => item.name"
              :item-value="item => item.bool"
              label="Structure Complète*"
              :rules="[$rules.requiredBoolean]"
              data-vv-name="select"
              outlined
              required
              clearable
              :disabled="isFormDisabled"
            ></v-select>
            <v-select
              tabindex="9"
              dense
              class="lateral-left"
              :items="formDiameter"
              v-model="coringForm.diameter"
              :rules="[$rules.requiredDiameter]"
              label="Diamètre*"
              outlined
              required
              clearable
              :disabled="isFormDisabled"
            ></v-select>
            <v-textarea
              tabindex="10"
              dense
              class="form-inputs m_textarea"
              v-model="coringForm.note"
              label="Note Supplémentaire"
              placeholder="Note Supplémentaire"
              rows="1"
              outlined
              auto-grow
              clearable
              :disabled="isFormDisabled"
            >
            </v-textarea>
            <div v-if="!isFormDisabled" class="d-flex justify-space-between">
              <div>
                <div
                  :class="
                    images.length != 0
                      ? 'file_input_personalized active'
                      : 'file_input_personalized'
                  "
                  @click="selectFile"
                >
                  <v-icon
                    v-if="images.length === 0"
                    medium
                    center
                    color="#2c0703"
                  >
                    mdi-paperclip
                  </v-icon>
                </div>
              </div>
              <input
                type="file"
                ref="fileInput"
                class="d-none"
                @change="updateFile"
              />

              <div v-if="canAddTechnicianHelpImage">
                <MediumButtonSlot @click="selectFile">
                  {{ $t("add_pictures") }}
                </MediumButtonSlot>
              </div>
            </div>
          </v-form>
          <div class="pt-4" v-for="(imag, index) in images" :key="index">
            <div class="center align-center d-flex">
              <v-btn
                @click="removePicture(index)"
                icon
                class="mr-2"
                v-if="!isFormDisabled"
                ><v-icon large color="red"> mdi-close </v-icon></v-btn
              >
              <div class="file_input_personalized__filename">
                {{ currentCoring.pictures[index].file.name }}
              </div>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="center" v-on="on" v-bind="attrs">
                  <v-img
                    class="campaign-form__imgBox"
                    target="_blank"
                    :src="imag"
                    @click="
                      isTogglePoppinPicture = true;
                      image = imag;
                    "
                  />
                </div>
              </template>
              <span>{{ $t("click_to_see_picture_preview") }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div
      v-if="
        !isFormInterfacesLayersHidden && currentCoring.status !== 'cancelled'
      "
    >
      <v-spacer></v-spacer>
      <h3 class="mb-2">Données des relevés</h3>
      <v-form :ref="'layerOrInterface_'">
        <div
          v-for="(currentLayer, index) in currentCoring.layersAndInterfaces"
          :key="currentLayer.layerId"
        >
          <!-- Layer Form -->
          <div v-if="currentLayer.type == 'layer'">
            <h4 class="mb-3">Couche {{ currentLayer.number }}</h4>
            <v-text-field
              dense
              :autofocus="isFormDisabled && index === 0"
              class="lateral-left"
              v-model="currentLayer.thickness"
              :tabindex="index * 7 + 1"
              label="Epaisseur des couches*"
              placeholder="Epaisseur des couches"
              rows="1"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-text-field>
            <v-select
              :tabindex="index * 7 + 2"
              dense
              class="lateral-left"
              :items="formMaterialCategoriesField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              v-model="currentLayer.materialCategory.id"
              :rules="[$rules.atLeastOneMaterial]"
              label="Catégorie de matériaux*"
              outlined
              clearable
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
            <v-combobox
              class="lateral-left"
              outlined
              v-if="isOtherCategory(currentLayer.materialCategory.id)"
              clearable
              :disabled="isformInterfacesLayersDisabled"
              v-model="currentLayer.otherMaterial"
              label="Type de matériaux*"
              :items="otherMaterials"
              required
              :tabindex="index * 7 + 3"
            >
            </v-combobox>
            <v-select
              v-else
              :tabindex="index * 7 + 3"
              dense
              class="lateral-left"
              :items="
                currentLayer.materialCategory.id === null
                  ? []
                  : currentLayerMaterials(currentLayer.materialCategory.id)
              "
              :item-text="item => $cap(item.name)"
              item-value="id"
              v-model="currentLayer.materialId"
              label="Type de matériaux*"
              outlined
              clearable
              required
              :disabled="
                isformInterfacesLayersDisabled ||
                currentLayer.materialCategory.id === null
              "
              :rules="[$rules.required]"
            ></v-select>
            <v-text-field
              :tabindex="index * 7 + 4"
              dense
              class="form-inputs"
              v-model="currentLayer.year"
              label="Année des matériaux"
              placeholder="Année des matériaux"
              :rules="[$rules.correctYearFormat]"
              rows="1"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
              @input="checkYear(currentLayer)"
            ></v-text-field>
            <!-- Layer State -->
            <v-select
              dense
              :tabindex="index * 7 + 5"
              class="lateral-left"
              :items="formLayersStateField"
              :item-text="item => $t(item.name)"
              :item-value="item => item.id"
              v-model="currentLayer.state_id"
              :rules="[$rules.layerState]"
              label="Etat de la couche*"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
          </div>
          <div v-if="currentLayer.type == 'interface'">
            <h4 class="mb-3">Interface {{ currentLayer.number }}</h4>
            <!-- Interface State -->
            <v-select
              dense
              :tabindex="index * 7 + 6"
              class="lateral-left"
              :items="formInterfaceStateField"
              :item-text="item => $t(item.name)"
              :item-value="item => item.id"
              v-model="currentLayer.state_id"
              :rules="[$rules.interfaceState]"
              label="Etat de l'interface*"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
          </div>
          <v-textarea
            :tabindex="index * 7 + 7"
            dense
            class="form-inputs m_textarea"
            v-model="currentLayer.note"
            placeholder="Notes additionnelles sur la couche"
            label="Notes"
            rows="1"
            auto-grow
            outlined
            clearable
            required
            :disabled="isformInterfacesLayersDisabled"
          ></v-textarea></div
      ></v-form>
      <div>
        <v-form
          v-if="newLayerAndInterface.displayed"
          ref="newLayerAndInterfaceForm"
          @submit.prevent="sendNewLayer"
          ><v-divider class="pb-2"></v-divider>
          <div>
            <h4 class="mb-3">
              Nouvelle interface {{ newLayerAndInterface.interface.number }}
            </h4>
            <!-- New Interface State -->
            <v-select
              dense
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 1"
              class="lateral-left"
              :items="formInterfaceStateField"
              :item-text="item => $t(item.name)"
              :item-value="item => item.id"
              v-model="newLayerAndInterface.interface.state_id"
              :rules="[$rules.interfaceState]"
              label="Etat de l'interface*"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
            <v-textarea
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 2"
              dense
              class="form-inputs m_textarea"
              v-model="newLayerAndInterface.interface.note"
              placeholder="Notes additionnelles sur la couche"
              label="Notes"
              rows="1"
              auto-grow
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-textarea>
          </div>
          <!--  New Layer Form -->
          <div>
            <h4 class="mb-3">
              Nouvelle couche {{ newLayerAndInterface.layer.number }}
            </h4>
            <v-text-field
              dense
              class="lateral-left"
              v-model="newLayerAndInterface.layer.thickness"
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 3"
              label="Epaisseur des couches*"
              placeholder="Epaisseur des couches"
              rows="1"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
              :rules="[$rules.required]"
            ></v-text-field>
            <v-select
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 4"
              dense
              class="lateral-left"
              :items="formMaterialCategoriesField"
              :item-text="item => item.name"
              :item-value="item => item.id"
              v-model="newLayerAndInterface.layer.materialCategory.id"
              :rules="[$rules.atLeastOneMaterial]"
              label="Catégorie de matériaux*"
              outlined
              clearable
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
            <v-select
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 5"
              dense
              class="lateral-left"
              :items="
                newLayerAndInterface.layer.materialCategory.id === null
                  ? []
                  : currentLayerMaterials(
                      newLayerAndInterface.layer.materialCategory.id
                    )
              "
              :item-text="item => $cap(item.name)"
              item-value="id"
              v-model="newLayerAndInterface.layer.materialId"
              label="Type de matériaux*"
              outlined
              clearable
              required
              :disabled="
                isformInterfacesLayersDisabled ||
                newLayerAndInterface.layer.materialCategory.id === null
              "
              :rules="[$rules.required]"
            ></v-select>
            <v-text-field
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 6"
              dense
              class="form-inputs"
              v-model="newLayerAndInterface.layer.year"
              label="Année des matériaux"
              placeholder="Année des matériaux"
              :rules="[$rules.correctYearFormat]"
              rows="1"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
              @input="checkYear(newLayerAndInterface.layer)"
            ></v-text-field>
            <!-- New Layer State -->
            <v-select
              dense
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 7"
              class="lateral-left"
              :items="formLayersStateField"
              :item-text="item => $t(item.name)"
              :item-value="item => item.id"
              v-model="newLayerAndInterface.layer.state_id"
              :rules="[$rules.layerState]"
              label="Etat de la couche*"
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-select>
            <v-textarea
              :tabindex="currentCoring.layersAndInterfaces.length * 7 + 8"
              dense
              class="form-inputs m_textarea"
              v-model="newLayerAndInterface.layer.note"
              placeholder="Notes additionnelles sur la couche"
              label="Notes"
              rows="1"
              auto-grow
              outlined
              clearable
              required
              :disabled="isformInterfacesLayersDisabled"
            ></v-textarea>
          </div>

          <nav>
            <medium-button-slot
              @click="newLayerAndInterface.displayed = false"
              >{{ $t("cancel") }}</medium-button-slot
            ><v-spacer></v-spacer
            ><high-button-slot _type="submit">{{
              $t("confirm")
            }}</high-button-slot>
          </nav>
        </v-form>
        <div
          v-if="!newLayerAndInterface.displayed && isFormInterfacesLayersActive"
          class="center"
        >
          <medium-button-slot @click="addLayer">{{
            $t("add_layer")
          }}</medium-button-slot>
        </div>
      </div>
    </div>

    <nav class="mt-6">
      <MediumButtonSlot
        v-if="currentCoring.status !== 'cancelled'"
        @click="toggleNewForm"
        >{{ isFormDisabled ? "Retour" : "Annuler" }}
      </MediumButtonSlot>
      <HighButtonSlot v-else @click="toggleNewForm">OK </HighButtonSlot>
      <v-spacer v-if="currentCoring.status !== 'cancelled'"></v-spacer>
      <HighButtonSlot
        class="lateral-right"
        v-if="formBtnStatus != 0"
        @click="formBtnClick"
        :_disabled="newLayerAndInterface.displayed"
      >
        Valider
      </HighButtonSlot>
    </nav>
    <CorePicturePopin
      v-if="isTogglePoppinPicture"
      @togglePoppinPicture="togglePoppinPicture"
      :currentPicture="image"
    >
    </CorePicturePopin>
  </form>
</template>

<script>
import coreStatus from "../../../utils/core";

export default {
  name: "CoreForm",
  components: {
    CorePicturePopin: () => import("@/components/coring/map/CorePicture.popin")
  },
  props: [
    "coordinates",
    "number",
    "workspaceId",
    "campaignStatus",
    "defaultValues",
    "infos"
  ],
  data() {
    return {
      layersAndInterfacesFormStates: {},
      display: [0],
      isCreateFormValid: false,
      currentCoring: { number: 0, pictures: [] },
      formRoads: [],
      roadWayField: [
        { id: 1, name: "Sens 1" },
        { id: 2, name: "Sens 2" }
      ],
      stCompleteField: [
        { bool: true, name: "Oui" },
        { bool: false, name: "Non" }
      ],
      formVMField: [
        {
          id: 1,
          name: "BDG"
        },
        {
          id: 2,
          name: "VR"
        },
        {
          id: 3,
          name: "VM"
        },
        {
          id: 4,
          name: "VL"
        },
        {
          id: 5,
          name: "VSVL"
        },
        {
          id: 6,
          name: "BAU"
        },
        {
          id: 7,
          name: "VD"
        },
        {
          id: 8,
          name: "VG"
        },
        {
          id: 9,
          name: "Trottoir"
        },
        {
          id: 10,
          name: "Parking"
        },
        {
          id: 11,
          name: "Voie bus"
        },
        {
          id: 12,
          name: "Piste cyclable"
        }
      ],
      lateralPosField: [
        { id: 1, name: "BdR G" },
        { id: 2, name: "Axe" },
        { id: 3, name: "BdR D" }
      ],
      stTypeField: [
        { id: 1, name: "Souple" },
        { id: 2, name: "Bitumineuse épaisse" },
        { id: 3, name: "Mixte" },
        { id: 4, name: "Rigide" }
      ],
      formDiameter: [50, 80, 100, 150],
      uploadedImgPng: [],
      images: [],
      image: null,
      isTogglePoppinPicture: false,
      canAddTechnicianHelpImage: false,
      isFormDisabled: false,
      formBtnStatus: false,
      otherMaterials: [],

      //interfaces and layers
      formInterfacesLayersStatus: 0,
      canValidateLayersAndInterfaces: false,

      //Layers
      allMaterialCategories: [],
      formMaterialCategoriesField: [],
      formLayersStateField: [
        { id: 1, name: "healthy_smooth" },
        { id: 2, name: "fragmented" },
        { id: 3, name: "cracked_smooth" },
        { id: 4, name: "cracked_fragments_ripped_off" },
        { id: 5, name: "mediocre_smooth" },
        { id: 6, name: "mediocre_fragments_ripped_off" },
        { id: 7, name: "crushed" }
      ],

      //interfaces
      formInterfaceStateField: [
        { id: 1, name: "bonded" },
        { id: 2, name: "semi-bonded" },
        { id: 3, name: "unbonded" }
      ],

      coringForm: {
        abscissa: null,
        diameter: null,
        direction: null,
        id: 0,
        campaign_id: this.$route.params.id,
        isFullStructure: null,
        lane: null,
        lane_id: null,
        lateralPosition: null,
        lateral_position_id: null,
        number: 0,
        note: null,
        road: null,
        structure_type_id: null,
        structureType: null,
        landmark: null,
        status: null,
        layersAndInterfaces: null
      },

      newLayerAndInterface: { displayed: false, layer: null, interface: null }
    };
  },
  computed: {
    lateralPosIsDisable() {
      if (
        this.coringForm.lane_id === 9 ||
        this.coringForm.lane_id === 10 ||
        this.coringForm.lane_id === 12
      ) {
        return true;
      }
      return false;
    },
    areLayersAndInterfacesFormsValid() {
      let isValid = true;
      for (const value of Object.values(this.layersAndInterfacesFormStates)) {
        isValid = true === value ? isValid : false;
      }
      return isValid;
    },
    isformInterfacesLayersDisabled() {
      return this.formInterfacesLayersStatus == 1;
    },
    isFormInterfacesLayersActive() {
      return this.formInterfacesLayersStatus == 2;
    },
    isFormInterfacesLayersHidden() {
      return this.formInterfacesLayersStatus == 0;
    }
  },
  async mounted() {
    this.formVMField.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.lateralPosField.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    this.stTypeField.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    let scopedThis = this;
    this.formLayersStateField.sort(function (a, b) {
      return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
    });
    this.formInterfaceStateField.sort(function (a, b) {
      return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
    });
    this.currentCoring = this.coordinates.filter(e => {
      return this.number == e.number;
    })[0];
    this.coringForm = {
      ...this.currentCoring
    };
    if (this.currentCoring.status == "waiting_validation_no_data") {
      for (const [key, value] of Object.entries(this.defaultValues)) {
        this.coringForm[key] = value;
      }
      this.coringForm.road = this.infos.lastRoad;
    }
    this.currentCoring.pictures = [];
    this.canAddTechnicianHelpImage = true;
    for (const picture of this.currentCoring.corePictures) {
      if (picture.type === "technicianHelp") {
        const picFile = await this.$api.documents.download(picture.path);
        const url = window.URL.createObjectURL(new Blob([picFile]));
        this.currentCoring.technicianHelpPicId = picture.id;
        this.canAddTechnicianHelpImage = false;
        this.images.push(url);
        this.currentCoring.pictures.push({
          file: { name: picture.path.split("\\").pop().split("/").pop() },
          id: picture.id,
          type: picture.type
        });
      }
    }
    this.coringIdField = "Carotte #" + this.currentCoring.number;
    this.getAllRoadsFromWorkspace();
    this.getMaterials();
    this.getOtherMaterials();
    this.updateSelectionInForm();
  },
  methods: {
    isOtherCategory(materialCategoryId) {
      const otherCategory = this.formMaterialCategoriesField.find(
        mc => mc.name === "OTHER"
      );

      if (undefined === otherCategory) return false;

      return otherCategory.id === materialCategoryId;
    },
    async getOtherMaterials() {
      const otherMaterials = await this.$api.materials.findOthersByCampaign(
        this.$route.params.id
      );
      this.otherMaterials = otherMaterials.map(materialObj => materialObj.name);
    },
    addLayer() {
      let numbersList = this.currentCoring.layersAndInterfaces.map(li => {
        return li.number;
      });
      let lastNumber = numbersList[numbersList.length - 1] + 1;
      this.newLayerAndInterface.interface = {
        id: null,
        state: null,
        note: null,
        number: lastNumber - 1,
        type: "interface"
      };
      this.newLayerAndInterface.layer = {
        id: null,
        state: null,
        thickness: null,
        year: null,
        businessNumber:
          this.currentCoring.layersAndInterfaces[0].businessNumber,
        materialCategory:
          this.currentCoring.layersAndInterfaces[0].materialCategory,
        material: null,
        note: null,
        number: lastNumber,
        type: "layer"
      };
      this.newLayerAndInterface.displayed = true;
    },
    currentLayerMaterials(mcID) {
      const mc = this.allMaterialCategories.find(mc => mc.id === mcID);
      if (undefined === mc) return [];
      return mc.materials;
    },
    handleRoadChange(change) {
      this.coringForm.road = change;
      this.coringForm = {
        ...this.coringForm,
        road: change
      };
    },
    checkYear(layer) {
      if (layer.year?.length > 4) {
        layer.year = layer.year.slice(1);
      }
    },
    async confirmCoringToCreate(isCreate) {
      if (!this.$refs.core_coreInfo.validate()) {
        return;
      }
      this.coringForm.lane = this.formVMField.find(
        l => l.id === this.coringForm.lane_id
      ).name;
      this.coringForm.structureType =
        this.coringForm.structure_type_id > 0
          ? this.stTypeField.find(
              st => st.id === this.coringForm.structure_type_id
            ).name
          : null;

      this.coringForm.road = await this.getRoadFromInput();

      this.formRoads.push(this.coringForm.road);

      this.coringForm.lateralPosition =
        this.coringForm.lateral_position_id > 0
          ? this.lateralPosField.find(
              lp => lp.id == this.coringForm.lateral_position_id
            ).name
          : null;

      this.confirmNewCore(isCreate);
    },
    async confirmingCoringToValidate() {
      if (!this.$refs.core_coreInfo.validate()) {
        return;
      }
      if (!this.$refs["layerOrInterface_"].validate()) {
        return;
      }
      this.currentCoring.layersAndInterfaces.forEach(async el => {
        if (el.note == null) el.note = "";
        if (el.type == "layer") {
          el.state = this.formLayersStateField.filter(
            stateField => stateField.id == el.state_id
          )[0].name;
          const coreLayer = {
            number: el.number,
            thickness: el.thickness,
            year: el.year,
            state: el.state,
            materialCategoryId: el.materialCategory.id,
            materialId: el.materialId,
            otherMaterial: el.otherMaterial,
            note: el.note
          };

          await this.$api.coreLayers.edit(el.id, coreLayer);
        } else if (el.type == "interface") {
          el.state = this.formInterfaceStateField.filter(
            stateField => stateField.id == el.state_id
          )[0].name;

          await this.$api.coreInterfaces.edit(el.id, {
            number: el.number,
            state: el.state,
            note: el.note
          });
        }
      });
      this.$emit("updateCoring", this.currentCoring);
      this.getOtherMaterials();
    },
    async createNewRoad(name) {
      return await this.$api.roads.create({
        name: name.toUpperCase(),
        category: "a_road",
        workspaceId: this.workspaceId
      });
    },
    removeAllPictures() {
      this.uploadedImgPng = [];
      this.images = [];
      this.$refs.fileInput.value = null;
    },
    formBtnClick() {
      if (this.currentCoring.status == "waiting_validation_no_data") {
        this.confirmCoringToCreate(true);
      } else if (this.currentCoring.status == "waiting") {
        this.confirmCoringToCreate(false);
      } else if (
        this.currentCoring.status == "sent_to_study_manager" &&
        this.campaignStatus == "taken"
      ) {
        this.confirmingCoringToValidate();
      }
    },
    async getAllRoadsFromWorkspace() {
      this.formRoads = await this.$api.roads.findByWorkspace(this.workspaceId);
    },
    async getRoadFromInput() {
      if (
        this.coringForm.road?.id != {} &&
        typeof this.coringForm.road == "object"
      ) {
        return this.coringForm.road;
      } else if (
        this.coringForm.road != "" &&
        typeof this.coringForm.road == "string"
      ) {
        //TODO swap "a_road" by what a category really is when we'll have the information
        return await this.createNewRoad(this.coringForm.road, "a_road");
      }
    },
    async getMaterials() {
      this.allMaterialCategories = await this.$api.materialCategories.findAll();
      this.formMaterialCategoriesField = this.allMaterialCategories.map(ele => {
        return { id: ele.id, name: ele.name };
      });
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    sendNewLayer() {
      if (!this.$refs.newLayerAndInterfaceForm.validate()) {
        return;
      }

      this.newLayerAndInterface.interface.state =
        this.formInterfaceStateField.filter(
          stateField =>
            stateField.id == this.newLayerAndInterface.interface.state_id
        )[0].name;
      if (this.newLayerAndInterface.interface.note == null)
        this.newLayerAndInterface.interface.note = "";

      this.newLayerAndInterface.layer.state = this.formLayersStateField.filter(
        stateField => stateField.id == this.newLayerAndInterface.layer.state_id
      )[0].name;
      if (this.newLayerAndInterface.layer.note == null)
        this.newLayerAndInterface.layer.note = "";

      this.$api.coreInterfaces
        .create({
          coreId: this.currentCoring.id,
          number: this.newLayerAndInterface.interface.number,
          state: this.newLayerAndInterface.interface.state,
          note: this.newLayerAndInterface.interface.note
        })
        .then(() => {
          this.currentCoring.layersAndInterfaces.push(
            this.newLayerAndInterface.interface
          );
        });

      this.$api.coreLayers
        .create({
          coreId: this.currentCoring.id,
          number: this.newLayerAndInterface.layer.number,
          state: this.newLayerAndInterface.layer.state,
          note: this.newLayerAndInterface.layer.note,
          thickness: this.newLayerAndInterface.layer.thickness,
          materialCategoryId:
            this.newLayerAndInterface.layer.materialCategory.id
        })
        .then(() => {
          this.currentCoring.layersAndInterfaces.push(
            this.newLayerAndInterface.layer
          );
        });

      this.newLayerAndInterface.displayed = false;
    },
    confirmNewCore(isCreate) {
      this.currentCoring.abscissa = this.coringForm.abscissa;
      this.currentCoring.diameter = this.coringForm.diameter;
      this.currentCoring.direction = this.coringForm.direction;
      this.currentCoring.isFullStructure = this.coringForm.isFullStructure;
      this.currentCoring.lane_id = this.coringForm.lane_id;
      this.currentCoring.lateral_position_id =
        this.coringForm.lateral_position_id;
      this.currentCoring.lateralPosition = this.coringForm.lateralPosition;
      this.currentCoring.note = this.coringForm.note;
      this.currentCoring.road = this.coringForm.road;
      this.currentCoring.structureType = this.coringForm.structureType;
      this.currentCoring.lane = this.coringForm.lane;
      this.currentCoring.landmark = this.coringForm.landmark;
      if (isCreate) {
        this.$emit("confirmNewCore", {
          ...this.currentCoring,
          status: "waiting_validation"
        });
      } else {
        this.$emit("updateCoring", {
          ...this.currentCoring,
          status: "waiting_validation"
        });
      }
    },
    removePicture(index) {
      this.images.splice(index, 1);
      this.currentCoring.pictures.splice(index, 1);
      this.$refs.fileInput.value = null;
      this.canAddTechnicianHelpImage = true;
    },
    toggleNewForm() {
      this.$emit("toggleNewForm", false, 0);
    },
    togglePoppinPicture(isTogglePoppinPicture) {
      this.isTogglePoppinPicture = isTogglePoppinPicture;
    },
    updateFile(e) {
      this.uploadedImgPng = e.target.files;
      for (let i = 0; i < e.target.files.length; i++) {
        this.canAddTechnicianHelpImage = false;
        let path = URL.createObjectURL(this.uploadedImgPng[i]);
        this.images.unshift(path);
        this.currentCoring.pictures.unshift({
          file: e.target.files[i],
          id: this.currentCoring.technicianHelpPicId,
          type: "technicianHelp",
          path: path
        });
      }
    },
    updateSelectionInForm() {
      if (this.coringForm.lane) {
        this.formVMField.forEach(ele => {
          if (ele.name == this.coringForm.lane) {
            this.coringForm.lane_id = ele.id;
          }
        });
      }

      if (this.coringForm.lateralPosition) {
        this.lateralPosField.forEach(ele => {
          if (ele.name === this.coringForm.lateralPosition)
            this.coringForm.lateral_position_id = ele.id;
        });
      }

      if (this.coringForm.structureType) {
        this.stTypeField.forEach(ele => {
          if (ele.name === this.coringForm.structureType)
            this.coringForm.structure_type_id = ele.id;
        });
      }

      // map-layers and interfaces
      if (
        typeof this.currentCoring.layersAndInterfaces !== "undefined" &&
        this.currentCoring.layersAndInterfaces.length !== 0
      ) {
        this.currentCoring.layersAndInterfaces.forEach(ele => {
          let layerOrInterface = ele;
          if (layerOrInterface.state && layerOrInterface.type == "interface") {
            this.formInterfaceStateField.forEach(el => {
              if (el.name == layerOrInterface.state)
                layerOrInterface.state_id = el.id;
            });
          } else if (
            layerOrInterface.state &&
            layerOrInterface.type == "layer"
          ) {
            this.formLayersStateField.forEach(el => {
              if (el.name == layerOrInterface.state)
                layerOrInterface.state_id = el.id;
            });
          }

          if (layerOrInterface.material) {
            layerOrInterface.materialCategory.materials.forEach(el => {
              if (layerOrInterface.material.id === el.id) {
                layerOrInterface.materialId = el.id;
              }
            });
          }
        });
      }
    }
  },
  watch: {
    coringForm() {
      let status = coreStatus.getCurrentStatus(this.coringForm.status);
      this.isFormDisabled =
        status.formStatus == 1 && this.campaignStatus !== "awaiting_validation";
      this.formBtnStatus = status.formBtnStatus;
      this.formInterfacesLayersStatus = status.interfacesAndLayersFormStatus;
      if (this.isFormDisabled) this.display = [1];
    }
  }
};
</script>

<style lang="scss">
.core_form {
  .v-expansion-panel-header {
    padding: 0px 0px !important;
  }
  .v-expansion-panel-content {
    padding: 0px 0px;
    &__wrap {
      padding: 0px 0px !important;
    }
  }
  .file_input_personalized__filename {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .form-lateral {
    display: flex;
    justify-content: space-around;
    align-items: center;

    p {
      margin-bottom: 24px !important;
      margin-left: 5px;
      margin-right: 5px;
    }

    &.disabled p {
      color: #bdbdbd;
    }
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: center;
    .lateral-left {
      margin-right: 20px;
    }
    .lateral-right {
      margin-left: 20px;
    }
  }
  .center {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .campaign-form__imgBox {
    margin: 20px 30px;
    max-width: 70%;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 4px;
    transition: border 0.2s ease;
    &:hover {
      border: 1px rgba(0, 0, 0, 0.8) solid;
    }
  }
}
</style>
